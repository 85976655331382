<template lang="pug">
  .setting-list
    .setting-list-header
      table
        thead
          th.car-class-name {{ $t("inventory_management.inventory_long_period.setting_list.car_class_name") }}
          th.shop-inventory(v-if="isDisplay['shop_inventory_limit']")
            | {{ $t("inventory_management.inventory_long_period.setting_list.shop_inventory") }}
          th.shared-inventory(v-if="isDisplay['shared_inventory_limit']")
            | {{ $t("inventory_management.inventory_long_period.setting_list.shared_inventory") }}
          th.sale-stop(v-if="isDisplay['sale_stop']")
            | {{ $t("inventory_management.inventory_long_period.setting_list.sale_stop") }}
    .setting-list-body
      table
        tbody
          TransitionGroup(
            name="fade-zoom"
            appear
            tag="div"
            class="transition-container"
          )
            tr(
              v-for="carClass in orderedCarClasses"
              :key="carClass.id"
              :class="{ inactive: !carClass.sync }"
            )
              td.car-class
                AppTooltip.inactive-red-icon(
                  v-if="hasIntersections(carClass.id)"
                  icon="exclamation-triangle"
                  placement="left"
                  boundary="body"
                  :title="$t('inventory_management.inventory_for_future.warning.has_intersections')"
                )
                CarClassName(
                  :car-class="carClass"
                  @remove-car-class="removeCarClass(carClass)"
                )
              td.shop-inventory(v-if="isDisplay['shop_inventory_limit']")
                AppNumberInput(
                  :disabled="inactive || !carClass.sync"
                  :value="get(settingsObject, `${carClass.id}.shop_inventory_limit`)"
                  @input="handleChangeInventory(carClass.id, $event, 'shop_inventory_limit')"
                )
              td.shared-inventory(v-if="isDisplay['shared_inventory_limit']")
                AppNumberInput(
                  :value="get(settingsObject, `${carClass.id}.shared_inventory_limit`)"
                  :disabled="inactive || !carClass.sync"
                  @input="handleChangeInventory(carClass.id, $event, 'shared_inventory_limit')"
                )
              td.sale-stop(v-if="isDisplay['sale_stop']")
                AppCheckbox(
                  :disabled="inactive || !carClass.sync"
                  :value="settingsObject[carClass.id].sale_stop || false"
                  @input="handleChangeInventory(carClass.id, $event, 'sale_stop')"
                )
</template>

<script>
  // misc
  import { reduce, every, includes, get, isEmpty } from "lodash-es"

  const defaultSettingsObject = {
    shop_inventory_limit: null,
    shared_inventory_limit: null,
    sale_stop: false
  }

  import { INVENTORY_FIELDS } from "@/components/InventoryManagement/SetForFutureDays/constants"

  export default {
    components: {
      AppCheckbox: () => import("@/components/elements/AppCheckbox"),
      AppNumberInput: () => import("@/components/elements/AppNumberInput"),
      AppTooltip: () => import("@/components/elements/AppTooltip"),
      CarClassName: () => import("@/components/InventoryManagement/SettingList/CarClassName")
    },

    props: {
      carClasses: {
        type: Array,
        default: () => []
      },
      intersections: {
        type: Object,
        default: () => {}
      },
      isSharedInventoryDisabled: {
        type: Boolean,
        default: false
      },
      settingsObject: {
        type: Object,
        default: () => ({})
      },
      inactive: {
        type: Boolean,
        default: false
      },
      inventoryFields: {
        type: Array,
        default: () => [...INVENTORY_FIELDS],
        validator: array => {
          return every(array, field => includes(INVENTORY_FIELDS, field))
        }
      }
    },

    watch: {
      carClasses(carClasses) {
        const settings = reduce(
          carClasses,
          (obj, { id }) => {
            obj[id] = this.settingsObject[id] || defaultSettingsObject

            return obj
          },
          {}
        )

        this.sendSettings(settings)
      }
    },

    computed: {
      isDisplay({ inventoryFields }) {
        return reduce(
          INVENTORY_FIELDS,
          (obj, field) => {
            obj[field] = includes(inventoryFields, field)

            return obj
          },
          {}
        )
      },

      orderedCarClasses() {
        return [...this.carClasses].sort((a, b) => a.order - b.order)
      }
    },

    methods: {
      get,

      hasIntersections(carClassId) {
        return !isEmpty(this.intersections[carClassId])
      },

      handleChangeInventory(carClassId, value, key) {
        const updatedSettings = { ...this.settingsObject[carClassId], [key]: value }

        this.sendSettings({ ...this.settingsObject, [carClassId]: updatedSettings })
      },

      sendSettings(settings) {
        this.$emit("change-settings", settings)
      },

      removeCarClass(carClass) {
        this.$emit("remove-car-class", carClass)
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/variables.sass"
  @import "@/assets/styles/transitions.sass"
  @import "@/assets/styles/tables/custom.sass"

  .setting-list
    &-header
      padding-right: 14px
      background-color: $default-gray-light

    &-body
      max-height: 260px
      overflow-x: auto

    table
      +custom-table
      width: 100%

      .shop-inventory,
      .shared-inventory
        width: 140px

      .sale-stop
        text-align: center
        width: 90px

      td
        vertical-align: middle

        &.car-class
          text-align: center
          padding: 10px 5px
          display: flex

          .inactive-red-icon
            margin-right: 5px
            ::v-deep
              svg
                color: $default-red

        &.sale-stop
          text-align: center

        ::v-deep
          .app-checkbox
            padding: 10px
            .app-checkbox-icon
              margin: 0 auto
</style>
